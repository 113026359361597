<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
            <template v-slot:body>
                <b-overlay :show="loading">
                    <b-row>
                        <b-col lg="12" sm="12">
                            <b-row>
                                <b-col lg="3" sm="12">
                                <p class="text-dark">{{ $t('irri_pump_main.complain_id') }} :</p>
                                </b-col>
                                <b-col lg="9" sm="12">
                                    <p class="text-dark">{{ pumpComplainId }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="3" sm="12">
                                <p class="text-dark">{{ $t('irri_pump_main.complained_by') }} :</p>
                                </b-col>
                                <b-col lg="9" sm="12">
                                    <p class="text-dark">{{ pumpComplainBy }}</p>
                                </b-col>
                            </b-row>
                            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                <b-form  @submit.prevent="handleSubmit(store)" @reset.prevent="reset" >
                                    <ValidationProvider name="Resolved Note" vid="resolve_note" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="3"
                                        label-for="resolve_note"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                        {{ $t('irri_pump_main.resolve_note')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-textarea
                                            useCustomImageHandler
                                            id="resolve_note"
                                            v-model="resolved.resolve_note"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-textarea>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                    <ValidationProvider name="Resolved Note (Bn)" vid="resolve_note_bn">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="3"
                                        :label="$t('irri_pump_main.resolve_note_bn')"
                                        label-for="resolve_note_bn"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <b-form-textarea
                                            id="resolve_note_bn"
                                            v-model="resolved.resolve_note_bn"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-textarea>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                    <div class="row">
                                        <div class="col-sm-3"></div>
                                        <div class="col text-right">
                                            <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                            &nbsp;
                                            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">{{ $t('globalTrans.cancel') }}</b-button>
                                        </div>
                                        </div>
                                </b-form>
                            </ValidationObserver>
                        </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { pumpComplainResolved } from '../../api/routes'

export default {
    name: 'FormLayout',
    props: ['id', 'complainId', 'complainBy'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    mounted () {
        core.index()
    },
    data () {
        return {
            loading: false,
            saveBtnName: this.$t('globalTrans.save'),
            resolved: {
                complain_id: this.$props.id,
                resolve_note: '',
                resolve_note_bn: ''
            },
            pumpComplainId: '',
            pumpComplainBy: ''
        }
    },
    created () {
        this.pumpComplainId = this.$props.complainId
        this.pumpComplainBy = this.$props.complainBy
    },
    methods: {
        async store () {
            this.loading = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadinState = { loading: false, listReload: false }
            result = await RestApi.postData(irriSchemeServiceBaseUrl, pumpComplainResolved, this.resolved)
            loadinState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadinState)
            this.loading = false
            if (result.success) {
                // push notification
                // const notification = result.notification
                // this.$socket.emit('send-notification', notification)

                this.$toast.success({
                    title: 'Success',
                    message: this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })
                this.$bvModal.hide('modal-1')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        }
    }
}
</script>
